// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-components-redirect-js": () => import("./../../src/components/redirect.js" /* webpackChunkName: "component---src-components-redirect-js" */),
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-me-js": () => import("./../../src/pages/about-me.js" /* webpackChunkName: "component---src-pages-about-me-js" */),
  "component---src-pages-about-me-pt-js": () => import("./../../src/pages/about-me.pt.js" /* webpackChunkName: "component---src-pages-about-me-pt-js" */),
  "component---src-pages-books-basic-python-index-js": () => import("./../../src/pages/books/basic-python/index.js" /* webpackChunkName: "component---src-pages-books-basic-python-index-js" */),
  "component---src-pages-books-basic-python-index-pt-js": () => import("./../../src/pages/books/basic-python/index.pt.js" /* webpackChunkName: "component---src-pages-books-basic-python-index-pt-js" */),
  "component---src-pages-books-index-js": () => import("./../../src/pages/books/index.js" /* webpackChunkName: "component---src-pages-books-index-js" */),
  "component---src-pages-books-index-pt-js": () => import("./../../src/pages/books/index.pt.js" /* webpackChunkName: "component---src-pages-books-index-pt-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-pt-js": () => import("./../../src/pages/index.pt.js" /* webpackChunkName: "component---src-pages-index-pt-js" */),
  "component---src-pages-scraping-js": () => import("./../../src/pages/scraping.js" /* webpackChunkName: "component---src-pages-scraping-js" */),
  "component---src-pages-scraping-pt-js": () => import("./../../src/pages/scraping.pt.js" /* webpackChunkName: "component---src-pages-scraping-pt-js" */),
  "component---src-templates-blog-post-js": () => import("./../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-book-page-js": () => import("./../../src/templates/book-page.js" /* webpackChunkName: "component---src-templates-book-page-js" */)
}

